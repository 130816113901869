import { writable } from 'svelte/store'


const defaultTimeout = 5 * 1000  // 5s

const createStore = function () {
  const { subscribe, update } = writable([])
  let count = 0

  const remove = function (toastId) {
    update(curr => curr.filter(m => m.toastId != toastId))
  }

  const add = function (type, message, undo, timeout=defaultTimeout) {
    const toastId = count
    count = count + 1
    update(curr => [...curr, {toastId, type, message, undo, timeout}])
    setTimeout(() => remove(toastId), timeout)
  }

  return {
    subscribe,
    remove,
    info: function (message, timeout) {
      add('info', message, undefined, timeout)
    },
    success: function (message, undo, timeout) {
      add('success', message, undo, timeout)
    },
    error: function (message, err, timeout) {
      add('error', message, undefined, timeout)
      // Re-throw the error, if provided, to make it easier to use in a promise chain
      if (err !== undefined) {
        throw err
      }
    },
  }
}

const toastStore = createStore()

export default toastStore
