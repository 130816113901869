<script>
  import { onMount } from 'svelte'

  import DOMPurify from 'dompurify';
  import marked from 'marked'

  import authStore from '../auth/store.js'
  import toastStore from './toast/store.js'

  import AppShell from './app-shell.svelte'


  export let data

  let entryId
  let currentView = 'entries'
  let entry
  let previous
  let next

  const markdown = function (text) {
    const rawHtml = marked(text)
    return DOMPurify.sanitize(rawHtml)
  }

  const scrollTop = function() {
    window.scrollTo(0, 0);
  }

  const updateSelectedEntry = function () {
    if (location.hash.length > 1) {
      entryId = location.hash.slice(1)

      const entryIndex = data.entries.findIndex(e => e.entry_id === entryId)
      if (entryIndex >= 0) {
        currentView = 'entries.' + entryId
        entry = data.entries[entryIndex]
        previous = data.entries[entryIndex - 1]
        next = data.entries[entryIndex + 1]
        scrollTop()
      } else {
        location.hash = '#'
      }
    } else {
      entryId = undefined
      currentView = 'entries'
      entry = undefined
      scrollTop()
    }
  }

  updateSelectedEntry()
</script>

<svelte:window on:hashchange={updateSelectedEntry}/>

<AppShell {data} {entry} {currentView}>
  <div class="content">
    {#if entry}
      {#each entry.posts as post}
        {#if post.post_type === 'message'}
          <div class="post message markdown">
            {@html markdown(post.message)}
          </div>
        {:else if post.post_type === 'quote'}
          <div class="post quote">
            <blockquote class="markdown">{@html markdown(post.quote)}</blockquote>
          </div>
        {:else if post.post_type === 'image'}
          <div class="post image">
            <img src="/images/{post.image}" alt={post.alt}>
            {#if post.caption}
              <p class="caption markdown">
                {@html markdown(post.caption)}
              </p>
            {/if}
          </div>
        {:else if post.post_type === 'video'}
          <div class="post video">
            <video controls preload src="/videos/{post.video}" alt={post.alt}>
              Sorry, your browser doesn't support embedded videos.
            </video>
            {#if post.caption}
              <p class="caption markdown">
                {@html markdown(post.caption)}
              </p>
            {/if}
          </div>
        {/if}
      {/each}
      <span class="spacer"></span>
      <div class="nav">
        {#if previous}
          <a class="previous greyLink" href="{'/#' + previous.entry_id}">Previous</a>
        {:else}
          <a class="previous greyLink" href="/#">Home</a>
        {/if}
        {#if next}
          <a class="next greyLink" href="{'/#' + next.entry_id}">Next</a>
        {:else}
          <a class="next greyLink" href="/#">Home</a>
        {/if}
      </div>
    {:else}
      <span class="spacer"></span>
      <div class="post welcome message markdown">
        {@html markdown(data.welcome)}
      </div>
      <div class="nav">
        <a class="start greyLink" href="{'/#' + data.entries[0].entry_id}">Let's Go!</a>
      </div>
      <span class="spacer"></span>
    {/if}
  </div>
</AppShell>

<style>
.post {
  background-color: white;
  box-shadow: 1px 0px 10px #0004;
  border-radius: 10px;
}

.post.welcome {
  text-align: center;
}

.post.message,
.post.quote,
.post.image > .caption,
.post.video > .caption {
  padding: 25px;

  font-size: 1.2em;
}

.post.image {
  overflow: hidden;
}
.post.image > img {
  display: block;

  max-width: 100%;
}

.post.video {
  overflow: hidden;
}
.post.video > video {
  display: block;

  max-width: 100%;
}

.markdown :global(*) {
  margin: 20px 0;
}
.markdown > :global(*:first-child) {
  margin-top: 0px;
}
.markdown > :global(*:last-child) {
  margin-bottom: 0px;
}

blockquote {
  margin: 15px;
  quotes: "“" "”" "‘" "’";
}
blockquote > :global(*:first-child) {
  text-indent: -1.5em;
}
blockquote > :global(*:first-child::before) {
  content: open-quote;
  color: var(--accent-light-color);
  font-size: 5em;
  line-height: 0;
  vertical-align: -0.35em;
}
blockquote > :global(*:last-child::after) {
  content: close-quote;
  visibility: hidden;
}

.nav {
  display: flex;
}
.nav > a {
  flex-grow: 1;
  flex-basis: 0;

  padding: 10px 25px;

  font-size: 1.3em;
  font-weight: bold;
}
.nav > a.start {
  text-align: center;
}
.nav > a.next {
  text-align: right;
}
</style>