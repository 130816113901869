const AUTH_STATE_KEY = 'authState'

export const updateAuthState = function (authState) {
  localStorage.setItem(AUTH_STATE_KEY, JSON.stringify(authState))
}

export const getAuthState = function () {
  const authStateStr = localStorage.getItem(AUTH_STATE_KEY)
  if (!authStateStr) {
    return undefined
  }
  const authState = JSON.parse(authStateStr)

  const expiresAt = new Date(authState.expiresAt)
  if (new Date() >=  expiresAt) {
    clearAuthState()
    return undefined
  }

  return authState
}

export const clearAuthState = function () {
  localStorage.removeItem(AUTH_STATE_KEY)
}
