<script>
  import { createEventDispatcher } from 'svelte'


  export let data
  export let currentView
  export let drawerClosed

  const dispatch = createEventDispatcher()

  const closeDrawer = function () {
    dispatch('closeDrawer')
  }
</script>

<div class="sidebar" class:drawerClosed>
  <nav>
    <h3>Thirty</h3>
    <ul>
      <li>
        <a class="greyLink" href="/#"
           class:current="{currentView === 'entries'}"
           on:click={closeDrawer}>
          Home
        </a>
        <ul>
          {#each data.entries as entry (entry.entry_id)}
            <li>
              <a class="greyLink" href="{'/#' + entry.entry_id}"
                 class:current="{currentView === ('entries.' + entry.entry_id)}"
                 on:click={closeDrawer}>
                <span class="label">{entry.name}</span>
              </a>
            </li>
          {/each}
        </ul>
      </li>
      <li>
        <a class="greyLink" href="/logout"
           on:click={closeDrawer}>
          Log Out
        </a>
      </li>
    </ul>
  </nav>
</div>

<style>
nav {
  padding: 10px 0 20px 10px;
}

nav h3 {
  padding: 10px 20px;
  margin-bottom: 10px;
}

nav ul {
  display: flex;
  flex-direction: column;
}

nav > ul > li {
  margin-top: 10px;
}
nav > ul > li:first-child {
  margin-top: 0;
}

nav ul li a {
  display: block;

  width: 100%;
  padding: 10px 20px;
}
nav ul li a.current {
  background-color: var(--main-fg-color);
  border-radius: 20px 0 0 20px;

  color: var(--main-bg-color);
}
nav ul li a.current:hover,
nav ul li a.current:active {
  color: var(--brand-color);
}

nav ul li ul li a {
  display: flex;
  align-items: center;

  padding: 10px 20px 10px 30px;
}
nav ul li ul li a > * {
  margin-right: 5px;
}
nav ul li ul li a > *:last-child {
  margin-right: 0;
}
nav ul li ul li a .label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2em;
}
</style>
