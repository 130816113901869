import authStore from './auth/store.js'

import Home from './components/home.svelte'


if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/sw.js')
}

authStore.requireAuth(() => {
  new Home({
    target: document.body,
  })
})
