import { writable } from 'svelte/store'


const createStore = function () {
  const { subscribe, set } = writable(null)

  const clear = function () {
    set(null)
  }

  const show = function (header, message, actions=[]) {
    set({header, message, actions})
  }

  return {
    subscribe,
    clear,
    show,
  }
}

const dialogStore = createStore()

export default dialogStore
