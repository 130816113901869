<script>
  import { onMount } from 'svelte'

  import dataStore from '../stores/data.js'

  import App from './app.svelte'

  let data

  onMount(() => {
    dataStore.maybeInit()
  })
</script>

{#if $dataStore}
  <App data={$dataStore}></App>
{/if}
