import axios from 'axios'
import IdTokenVerifier from 'idtoken-verifier'

import { OIDC_CALLBACK_ENDPOINT } from './consts.js'
import { hashUrlSafe } from './misc.js'


const OIDC_TOKEN_ENDPOINT = new URL('/api/oauth2/token', process.env.OIDC_ISSUER).href
const OIDC_JWKS_ENDPOINT = new URL('/.well-known/jwks.json', process.env.OIDC_ISSUER).href
// How long before a token expires should we consider it expired.
const TOKEN_EXPIRY_BUFFER_SECS = 30

export class UserForbidden extends Error {
  constructor(...params) {
    super(...params)

    // For V8
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, UserForbidden)
    }

    this.name = 'UserForbidden'
  }
}

const jwksCache = {
  get: function (cacheKey) {
    const cacheString = localStorage.getItem('jwksCache')
    if (cacheString === null) {
      return null
    }
    const cache = JSON.parse(cacheString)
    if (cache.hasOwnProperty(cacheKey)) {
      return cache[cacheKey]
    } else {
      return null
    }
  },
  set: function (cacheKey, keyInfo) {
    const cacheString = localStorage.getItem('jwksCache')
    const cache = (cacheString === null) ? {} : JSON.parse(cacheString)
    cache[cacheKey] = keyInfo
    localStorage.setItem('jwksCache', JSON.stringify(cache))
  },
  has: function (cacheKey) {
    return this.get(cacheKey) !== null
  }
}

const verifier = new IdTokenVerifier({
  issuer: process.env.OIDC_ISSUER,
  audience: process.env.OIDC_CLIENT_ID,
  jwksCache: jwksCache,
  jwksURI: OIDC_JWKS_ENDPOINT,
})

const verifyIdToken = function (idToken, nonce) {
  return new Promise((resolve, reject) => {
    verifier.verify(idToken, nonce, (error, payload) => {
      if (error) {
        reject(error)
      } else {
        resolve(payload)
      }
    })
  })
}

export const fetchTokens = function (authCode, nonce) {
  const tokenRequestData = new FormData()
  tokenRequestData.append('client_id', process.env.OIDC_CLIENT_ID)
  tokenRequestData.append('grant_type', 'authorization_code')
  tokenRequestData.append('redirect_uri', OIDC_CALLBACK_ENDPOINT)
  tokenRequestData.append('code', authCode)

  const request_dt = new Date()

  return axios.post(OIDC_TOKEN_ENDPOINT, tokenRequestData)
    .then(response => {
      const idToken = response.data.id_token
      const expiresInMs = (response.data.expires_in - TOKEN_EXPIRY_BUFFER_SECS) * 1000
      const expiresAt = new Date(request_dt.getTime() + expiresInMs)

      // The auth request included a hash of the nonce, not the nonce itself.
      return verifyIdToken(idToken, hashUrlSafe(nonce))
        .then(jwt => {
          return axios.get('/api/check', {headers: {'Authorization': 'Bearer ' + idToken}})
            .then(response => {
              if (response.data.allowed) {
                return {idToken, jwt, expiresAt}
              } else {
                throw new UserForbidden('User not allowed.')
              }
            })
        })
    })
}