import { writable } from 'svelte/store'

import axios from 'axios'

import dialogStore from '../components/dialog/store.js'

import { OIDC_SILENT_MESSAGE_CODE } from './consts.js'
import { constructAuthRequest } from './misc.js'
import { updateAuthState, getAuthState } from './state.js'
import { fetchTokens, UserForbidden } from './tokens.js'


const SILENT_REFRESH_TIMEOUT_SECS = 5
const SESSION_MAINTENANCE_INTERVAL_SECS = 10
const REQUEST_TIMEOUT_SECS = 5

const APP_LOGIN_ENDPOINT = '/login'

class LoginRequired extends Error {
  constructor(...params) {
    super(...params)

    // For V8
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, LoginRequired)
    }

    this.name = 'LoginRequired'
  }
}

const redirectToLogin = function (continueUrl, forceLogin=false) {
  const url_params = {
    continue: continueUrl,
  }
  if (forceLogin) {
    url_params['force_login'] = 'true'
  }

  const url = APP_LOGIN_ENDPOINT + '?' + Object.keys(url_params).map((key) => {
    return key + '=' + encodeURIComponent(url_params[key])
  }).join('&')
  location.href = url
}

// Create an auth object.
//
// The auth object is a svelte store containing the last known auth tokens. The store is updated
// whenever the auth state is changed in this window. It is also updated at regular intervals in
// case the auth state has been changed in another window.
//
// The initial store value may be undefined if the user isn't logged in yet.
// After the user has been logged in once, the store tokens will _not_ be reset to undefined/null
// if the user needs to log in again - i.e. UI elements depending on this store won't be updated.
// It's up to other logic to get the user to log in again when/if appropriate.
//
// Methods are also provided to work with auth in other ways.
const createStore = function () {
  let userId

  // Extract tokens from the stored auth state.
  // Throws LoginRequired errors if the auth state is missing, expired, or the user has changed.
  const getTokens = function () {
    const authState = getAuthState()
    if (authState === undefined) {
      throw new LoginRequired('No stored auth state')
    }
    const idToken = authState.idToken
    const jwt = authState.jwt

    if (userId === undefined) {
      userId = jwt.sub
    } else if (jwt.sub !== userId) {
      throw new LoginRequired('Stored auth state user changed')
    }

    return {idToken, jwt}
  }

  // Set up the store.
  try {
    const initialTokens = getTokens()
    var { subscribe, set } = writable(initialTokens)
  } catch (error) {
    if (error instanceof LoginRequired) {
      var { subscribe, set } = writable()
    } else {
      throw error
    }
  }

  // Update the store with the latest auth tokens.
  const updateStore = function () {
    // This may throw an exception if the auth tokens are missing/expired/invalid/etc.
    // The store won't be updated in this case.
    const tokens = getTokens()
    set(tokens)
    return tokens
  }

  // Try and silently refresh the auth state by performing OIDC flow with `prompt=none`.
  // Will update the auth state and store if successful, and return the tokens.
  const silentAuthRefresh = function () {
    // NOTE: We don't use `id_token_hint` to prevent getting a token for a different user.
    //       This parameter isn't supported yet, and may never be due to concerns around passing an
    //       ID token in the URL - it will get logged.
    //       Instead, getTokens() ensures we don't ever transparently change users.
    // 's' state prefix indicates a silent flow.
    const {state, nonce, authUrl} = constructAuthRequest('s', 'none')

    const silentOidcState = {state, nonce}

    const iframe = document.createElement('iframe')
    iframe.style.display = "none"
    iframe.src = authUrl
    document.body.appendChild(iframe)

    return new Promise((resolve, reject) => {
      const listenerFunc = function (event) {
        if (event.origin === window.location.origin && event.data) {
          const eventData = JSON.parse(event.data)
          if (eventData.code === OIDC_SILENT_MESSAGE_CODE) {
            const url = new URL(eventData.location)
            document.body.removeChild(iframe)
            removeEventListener('message', listenerFunc)
            resolve(url)
          }
        }
      }
      addEventListener('message', listenerFunc)

      // Reject the promise and cleanup if the iframe doesn't report back fast enough
      setTimeout(() => {
        // If the iframe still exists, it hasn't reported back and we need to clean up.
        if (document.body.contains(iframe)) {
          document.body.removeChild(iframe)
          removeEventListener('message', listenerFunc)
          reject(new Error('Silent OIDC refresh timed out'))
        }
      }, SILENT_REFRESH_TIMEOUT_SECS * 1000)

    })
      .then(url => {
        const rawQsState = url.searchParams.get('state')

        if (!rawQsState) {
          throw new Error('Silent OIDC callback state missing')
        }

        if (rawQsState[0] !== 's') {
          throw new Error('Silent OIDC callback state invalid')
        }
        const qsState = rawQsState.slice(1)  // Drop the prefix character

        if (qsState !== silentOidcState.state) {
          throw new Error('Silent OIDC callback state missmatch')
        }

        const qsError = url.searchParams.get('error')
        const qsCode = url.searchParams.get('code')
        if (qsError) {
          if (qsError === 'login_required' || qsError === 'consent_required') {
            throw new LoginRequired('Login requires user prompt')
          } else {
            throw new Error('Silent OIDC error: ' + qsError)
          }
        }
        if (!qsCode) {
          throw new Error('Silent OIDC callback code missing')
        }

        return fetchTokens(qsCode, silentOidcState.nonce)
          .then(updateAuthState)
          .then(updateStore)
      })
  }

  const showLoginRequiredDialog = function () {
    dialogStore.show('Login Required',
                     'Your session has expired. Please log in again to continue.',
                     [{name: 'Login', action: () => redirectToLogin(location.href, true)}])
  }

  // Maintain the user's session info by updating the store with the latest auth tokens, and running
  // a silent refresh if they've expired. If the session can't be refreshed, show a login required
  // dialog to the user.
  const maintainSession = function () {
    try {
      updateStore()
    } catch (error) {
      // Ignore other types of errors. If there's something wrong with the session management other
      // than the user needing to log in, e.g. the network is down, it may resolve itself before an
      // up to date set of tokens is actually required.
      if (error instanceof LoginRequired) {
        // Try a silent refresh
        silentAuthRefresh()
          .catch(error => {
            if (error instanceof LoginRequired || error instanceof UserForbidden) {
              // Silent refresh didn't work, so user needs to be directed to login.
              // Show a dialog so the user knows whats happening, and can trigger the login redirect
              // when they want to.
              showLoginRequiredDialog()
            }
          })
      }
    }
  }
  // Run session maintenance periodically.
  window.setInterval(maintainSession, SESSION_MAINTENANCE_INTERVAL_SECS * 1000)

  return {
    subscribe,

    // Run a function if the user is logged in.
    // Will redirect to login if the user isn't logged in.
    requireAuth: function (func) {
      return Promise.resolve()
        .then(getTokens)
        .then(func, error => {
          if (error instanceof LoginRequired) {
            // First try a silent refresh
            return silentAuthRefresh()
              .then(func, error => {
                // Silent refresh didn't work, so redirect to login.
                //
                // Ignore the fact the error could be something other than LoginRequired. We've
                // already gotten LoginRequired earlier so we know a login is required. Even if
                // something else went wrong with the silent refresh, it's safer to push the user to
                // full login.
                redirectToLogin(location.href)
                // Don't throw the error - just don't run the func, and wait for the redirect.
              })
          } else {
            throw error
          }
        })
    },

    // Make a XHR request with authentication added.
    // Will show a login required dialog if the user isn't logged in.
    request: function (config) {
      return Promise.resolve()
        .then(getTokens)
        .catch(error => {
          if (error instanceof LoginRequired) {
            // First try a silent refresh
            return silentAuthRefresh()
              .catch(error => {
                // Silent refresh didn't work, so the user needs to do a full login.
                //
                // Ignore the fact the error could be something other than LoginRequired. We've
                // already gotten LoginRequired earlier so we know a login is required. Even if
                // something else went wrong with the silent refresh, it's safer to push the user to
                // full login.
                //
                // Show a dialog so the user knows whats happening, and can trigger the login
                // redirect when they want to.
                showLoginRequiredDialog()
                // Throw error since the request effectively failed. Prevents later then() calls
                // from firing before the browser actually performs the redirect.
                throw error
              })
          } else {
            throw error
          }
        })
        .then(tokens => {
          if (config.hasOwnProperty('headers')) {
            config.headers['Authorization'] = 'Bearer ' + tokens.idToken
          } else {
            config.headers = {'Authorization': 'Bearer ' + tokens.idToken}
          }
          if (!config.hasOwnProperty('timeout')) {
            config.timeout = REQUEST_TIMEOUT_SECS * 1000
          }
          return axios.request(config)
        })
    },
  }
}

const authStore = createStore()

export default authStore
