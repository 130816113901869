import blake from 'blakejs'

import { generateId } from '../misc.js'

import { OIDC_CALLBACK_ENDPOINT } from './consts.js'


const HASH_BYTES = 16
const OIDC_AUTH_ENDPOINT = new URL('/oauth2/authorize', process.env.OIDC_ISSUER).href

export const hashUrlSafe = function (nonce) {
  const hashIntArray = blake.blake2b(nonce, undefined, HASH_BYTES)
  const hashBinaryString = String.fromCharCode.apply(null, hashIntArray)
  const hashBase64 = btoa(hashBinaryString)
  // Make URL safe
  return hashBase64.replace(/[\+]/g, '-').replace(/\//g, '_').replace(/\=+$/g, '')
}

export const constructAuthRequest = function (statePrefix, prompt) {
  const state = generateId()
  const nonce = generateId()

  const url_params = {
    response_type: 'code',
    client_id: process.env.OIDC_CLIENT_ID,
    scope: 'openid',
    state: statePrefix + state,
    // Keep the original nonce a secret, to avoid auth code replay attacks.
    // If an attacker manages to steal an auth code for a user from the callback URL, they may be
    // able to insert fake OIDC state into their browser and call the callback URL themselves
    // (before the user does, consuming the auth code). This would log them in as the user.
    // However, if they only have the hash of the nonce from the OIDC request url, not the original
    // nonce value, they can't construct a correct OIDC state and their request will be rejected.
    nonce: hashUrlSafe(nonce),
    redirect_uri: OIDC_CALLBACK_ENDPOINT,
  }
  if (prompt) {
    url_params['prompt'] = prompt
  }

  const authUrl = OIDC_AUTH_ENDPOINT + '?' + Object.keys(url_params).map((key) => {
    return key + '=' + encodeURIComponent(url_params[key])
  }).join('&')

  return {state, nonce, authUrl}
}
