import { get, writable } from 'svelte/store'

import authStore from '../auth/store.js'
import toastStore from '../components/toast/store.js'


const createStore = function () {
  const { subscribe, set } = writable()

  let initPromise
  let initialised = false

  const refresh = function () {
    return authStore.request({method: 'get', url: '/api/data'})
      .then(response => {
        const data = response.data
        set(data)
        return data
      })
      .catch(e => toastStore.error('Failed to load data', e))
  }

  const maybeInit = function () {
    if (initPromise === undefined) {
      initPromise = refresh()
      initPromise
        .then(() => {
          initialised = true
        })
        .catch(() => {
          // If init failed, reset the promise so future calls will try again
          initPromise = undefined
        })
      return initPromise
    } else if (!initialised) {
      // Init has started but not finished, so return the existing promise
      return initPromise
    } else {
      // Fully initialised, so return the current value
      const dummyStore = {subscribe}
      return Promise.resolve(get(dummyStore))
    }
  }

  return {
    subscribe,
    refresh,
    maybeInit,
  }
}

const dataStore = createStore()

export default dataStore
