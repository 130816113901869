<script>
  import { tick } from 'svelte'

  import toasts from './store.js'


  const themes = {
    info: '#bb2124',
    success: '#22bb33',
    error: '#f0ad4e',
  }

  const undo = function (toast) {
    if (!toast.undo) {
      return Promise.reject(new Error('undo() called for toast with no undo callback'))
    }
    return toast.undo()
      // Remove undone toast after UI has updated (tick), and a short delay (500ms).
      // Clearer for users than removing it immediately, which would silently swap it for the new
      // undo action toast.
      .then(tick)
      .then(() => setTimeout(() => toasts.remove(toast.toastId), 500))
  }
  const remove = function(toast) {
    toasts.remove(toast.toastId)
  }
</script>

{#if $toasts.length !== 0}
<ul class="toasts">
  {#each $toasts as toast (toast.toastId)}
    <li class="toast">
      <span class="message">
        {#if toast.type === 'success'}
          <svg class="icon floatLeft" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z">
            </path>
          </svg>
        {:else if toast.type === 'error'}
          <svg class="icon floatLeft" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z">
            </path>
          </svg>
        {:else}
          <svg class="icon floatLeft" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z">
            </path>
          </svg>
        {/if}
        {toast.message}
      </span>
      {#if toast.undo}
        <button on:click={() => undo(toast)}>Undo</button>
      {/if}
      <button on:click={() => remove(toast)}>Close</button>
    </li>
  {/each}
</ul>
{/if}

<style>
ul.toasts {
  position: absolute;
  bottom: 30px;
  left: 30px;
  z-index: 100;

  display: flex;
  flex-direction: column;
  align-items: stretch;

  list-style: none;

  width: 400px;
  padding: 0;
  margin: 0;
}

li.toast {
  display: flex;

  margin-bottom: 5px;

  background-color: var(--main-fg-color);
  border-radius: 5px;
  box-shadow: 1px 0px 10px #0004;

  color: var(--main-bg-color);
}
li.toast:last-child {
  margin-bottom: 0;
}

li.toast > * {
  display: flex;
  align-items: center;

  padding: 15px;

  border: none;
  border-radius: 0;
}
li.toast > *:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

li.toast > .message {
  flex-grow: 1;
}

li.toast > button {
  flex-shrink: 0;

  color: var(--main-bg-color);
}
li.toast > button:hover,
li.toast > button:active {
  color: var(--brand-color);
}

li.toast svg.icon path {
  stroke: var(--main-bg-color);
}

@media only screen and (max-width: 650px) {
  ul.toasts {
    left: 10%;

    width: 80%;
  }
}
</style>
