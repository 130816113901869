<script>
  import dialogStore from './store.js'
</script>

{#if $dialogStore !== null}
<div class="dialogLayer">
  <div class="dialog">
    <div class="header">{$dialogStore.header}</div>
    <div class="body">
      <p class="message">
        {$dialogStore.message}
      </p>
      <div class="actions">
        <span class="spacer"></span>
        {#each $dialogStore.actions as action (action.name)}
          <button on:click={action.action}>{action.name}</button>
        {/each}
      </div>
    </div>
  </div>
</div>
{/if}

<style>
div.dialogLayer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
  padding: 30px;

  background-color: #F9F9F988;
}

div.dialog {
  max-width: 600px;

  background-color: var(--main-bg-color);
  border-radius: 10px;
  box-shadow: 1px 0px 10px #0004;
}

div.dialog .header {
  padding: 15px;

  background-color: var(--main-fg-color);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  color: var(--main-bg-color);
}

div.dialog .body {
  padding: 15px;
}
div.dialog .body .message {
  padding: 10px;
  margin-bottom: 15px;

  text-align: center;
}

div.dialog .body .actions {
  display: flex;
}
div.dialog .body .actions > button {
  flex-shrink: 0;

  margin-right: 15px;
}
div.dialog .body .actions > button:last-child {
  margin-right: 0;
}

@media only screen and (max-width: 650px) {
  div.dialogLayer {
    padding: 15px;
  }
  div.dialog {
    max-width: 100%;
  }
}
</style>
