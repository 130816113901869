<script>
  import Dialog from './dialog'
  import Sidebar from './sidebar.svelte'
  import Toasts from './toast'


  export let data
  export let entry
  export let currentView

  let drawerClosed = true
  let removeOutsideClickHandler

  const closeSidebar = function () {
    if (!drawerClosed) {
      drawerClosed = true
      removeOutsideClickHandler()
    }
  }

  const openSidebar = function () {
    if (drawerClosed) {
      const sidebarElement = document.querySelector('.sidebar')
      const sidebarButtonElement = document.querySelector('.sidebarButton')

      const outsideClickListener = function (event) {
        if (!(sidebarElement.contains(event.target) || sidebarButtonElement.contains(event.target))) {
          closeSidebar()
        }
      }

      removeOutsideClickHandler = function () {
        document.removeEventListener('click', outsideClickListener)
        removeOutsideClickHandler = undefined
      }

      drawerClosed = false
      document.addEventListener('click', outsideClickListener)
    }
  }

  const toggleSidebar = function () {
    if (drawerClosed) {
      openSidebar()
    } else {
      closeSidebar()
    }
  }
</script>

<Sidebar {data} {currentView} {drawerClosed} on:closeDrawer={closeSidebar}></Sidebar>
<main>
  <div class="header">
    <button class="sidebarButton iconButton" on:click={toggleSidebar} title="Menu">
      <svg class="icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M4 6h16M4 12h8m-8 6h16">
        </path>
      </svg>
    </button>
    {#if currentView === 'entries'}
      <div class="logo"><h3>Thirty</h3></div>
    {:else}
      <div class="logo"><h3>{entry.name}</h3></div>
    {/if}
    <span class="spacer"></span>
  </div>
  <slot></slot>
</main>

<Dialog></Dialog>
<Toasts></Toasts>
